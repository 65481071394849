import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugin/axios'
import './assets/css/index.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible/flexible'
import i18n from './langurage/index'
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('carToken') // 是否登录
  if (to.path !== '/login' && !isLogin) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})
Vue.use(Vant);
Vue.config.productionTip = false
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
