import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: resolve => require(['@/layout/index.vue'], resolve),
    reject: '/home',
    children: [
      {
        path: '/',
        name: '首页',
        reject: '/home',
        component: resolve => require(['@/views/Home.vue'], resolve)
      }, {
        path: '/secondHandCar',
        name: '二手车',
        component: resolve => require(['@/views/secondHandCar.vue'], resolve)
      }, {
        path: '/self',
        name: '个人中心',
        component: resolve => require(['@/views/self.vue'], resolve)
      }
    ]
  },
  {
    path: '/testscore',
    name: '开始考试',
    component: resolve => require(['@/views/testscore.vue'], resolve)
  },
  {
    path: '/examination',
    name: '考试内容',
    component: resolve => require(['@/views/examination.vue'], resolve)
  },
  {
    path: '/result',
    name: '考试结果',
    component: resolve => require(['@/views/result.vue'], resolve)
  },
  {
    path: '/register',
    name: '报名考试',
    component: resolve => require(['@/views/register.vue'], resolve)
  },
  {
    path: '/myplaybill',
    name: '我的海报',
    component: resolve => require(['@/views/myplaybill.vue'], resolve)
  },
  {
    path: '/popularize',
    name: '我的推广',
    component: resolve => require(['@/views/popularize.vue'], resolve)
  },
  {
    path: '/information',
    name: '我的消息',
    component: resolve => require(['@/views/information.vue'], resolve)
  },
  {
    path: '/exercise',
    name: '习题练习',
    component: resolve => require(['@/views/exercise.vue'], resolve)
  },
  {
    path: '/exerover',
    name: '习题结束',
    component: resolve => require(['@/views/exerover.vue'], resolve)
  },
  {
    path: '/pay',
    name: '支付',
    component: resolve => require(['@/views/pay.vue'], resolve)
  },
  {
    path: '/paysucess',
    name: '支付成功',
    component: resolve => require(['@/views/paysucess.vue'], resolve)
  },
  {
    path: '/myorder',
    name: '我的订单',
    component: resolve => require(['@/views/myorder.vue'], resolve)
  },
  {
    path: '/accredit',
    name: 'dengs',
    component: resolve => require(['@/views/accredit.vue'], resolve)
  },
  {
    path: '/login',
    name: '登录',
    component: resolve => require(['@/views/login.vue'], resolve)
  }, {
    path: '/languageChoose',
    name: '语言选择',
    component: resolve => require(['@/views/languageChoose.vue'], resolve)
  }, {
    path: '/specialProject',
    name: '专项练习',
    component: resolve => require(['@/views/specialProject.vue'], resolve)
  }, {
    path: '/mastickandCollect',
    name: '错题练习',
    component: resolve => require(['@/views/mastickandCollect.vue'], resolve)
  }, {
    path: '/secondcarDetail',
    name: '二手车详情',
    component: resolve => require(['@/views/secondcarDetail.vue'], resolve)
  }, {
    path: '/videoExplanation',
    name: '视频讲解',
    component: resolve => require(['@/views/videoExplanation.vue'], resolve)
  }, {
    path: '/selectBrand',
    name: '选择品牌',
    component: resolve => require(['@/views/selectbrand.vue'], resolve)
  }, {
    path: '/examination1',
    name: '考场模拟',
    component: resolve => require(['@/views/examination1.vue'], resolve)
  }, {
    path: '/realExamination',
    name: '真实考试',
    component: resolve => require(['@/views/realExamination.vue'], resolve)
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
